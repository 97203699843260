import {
  exportData,
  terminalActivityById, // 作废
  shopInfoCkApplyGrid, // 仓库列表
} from "./api.js";
import Util from "@/utils/utils";
export default {
  data() {
    return {
      pageLoadFlag:false, // 页面loading
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          title: "管辖的仓库"
        }
      ],
      exportLoading: false, // 导出按钮loading
      hzVisible: false, // 合作关系弹窗
      arg: {}, // 表单入参
      tableData: [], // 仓库列表数据
      tableColumns: [ // 列表表头
        // {
        //   label: "分销商CIS编码",
        //   prop: "custCisCode",
        //   width: 200,
        //   ellipsis: true,
        // },
        // {
        //   label: "分销商名称",
        //   prop: "custInfoFullName",
        //   width: 150,
        //   ellipsis: true,
        // },
        {
          label: "仓库名称",
          prop: "fullName",
          width: 150,
          ellipsis: true,
        },
        {
          label: "仓库编码",
          prop: "cisCode",
          width: 150,
          ellipsis: true,
        },
       
        {
          label: "所属商家名称",
          prop: "custInfoFullName",
          width: 150,
          ellipsis: true,
        },
        {
          label: "仓库联系人",
          prop: "legalPerson",
          width: 150,
          ellipsis: true,
        },
        {
          label: "仓库联系电话",
          prop: "bossTel",
          width: 150,
          ellipsis: true,
        },
        {
          ellipsis: true,
          label: "行政区划",
          slot: "districts",
          width: 300,
        },
        {
          label: "详细地址",
          prop: "shAddress",
          width: 400,
          ellipsis: true,
        },
        {
          label: "审批状态",
          prop: "checkStatus",
          width: 200,
          ellipsis: true,
        },
        {
          label: "驳回原因",
          prop: "rejectReason",
          width: 200,
          ellipsis: true,
        },
        {
          label: "数据来源",
          prop: "source",
          width: 100,
          ellipsis: true,
        },
        {
          ellipsis: true,
          fixed: "right",
          label: "操作",
          slot: "btnClick",
          width: 220,
        },
      ],
      tableLoading: false,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10,
      },
      partnerName: "",
    };
  },
  props: {
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    this.getData();
  },
  methods: {
    // @ 作废
    goTerminalActivityById(feed) {
      let params = {
        id: feed.id || "",
      };
      this.tableLoading = true;
      terminalActivityById(params).then(res => {
        this.tableLoading = false; 
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.getData();
        }
      }).catch(err=>{
        this.tableLoading = false;
      });
    },
    // @ 编辑
    goEdit(row) {
      sessionStorage.setItem("selectedRow", JSON.stringify([row]));
      let routeUrl = this.$router.resolve({
        path: "/jurisdiction/warehouseAddEdit",
        // query: { type: "edit", id: feed.id },
        // cs
        query: { type: "edit"},
      });
      window.open(routeUrl.href, "_blank");
    },
    // @ 查看流程
    goFlow(feed) {
      window.open(
        `/auth-api/displayFlow?param=${feed.processInstId}#/`
      );
    },
    // @ 下载文件
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function(e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      };
    },
    // @ 查询条件
    searchFun(arg) {
      this.pager.pageNo = 1;
      this.arg = arg[0];
      this.getData();
    },
    // @ 列表数据
    async getData() {
      this.tableLoading = true;
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        gridManager: this.$store.state.user.userInfo.account.account, // todo 'wangningjing'
        custId: this.$store.state.user.userInfo.customer.id,// this.shopId, // this.$store.state.user.userInfo.customer.id
        ...this.arg,
      };
      const res = await shopInfoCkApplyGrid(data);
      this.tableData = res.data.list;
      // this.pager.pageNo = res.data.page
      // this.pager.pageSize = res.data.pageSize
      this.pager.count = res.data.totalRows;
      this.total = res.data.totalRows;
      // // 设置表格唯一id
      if (res.data.list) {
        res.data.list.forEach((it) => {
          it.uid = it.id;
        });
      }

      this.tableLoading = false;
    },

    // @ 新增
    add() {
      let routeUrl = this.$router.resolve({
        path: "/jurisdiction/warehouseAddEdit",
        query: { type: "add" },
      });
      window.open(routeUrl.href, "_blank");
    },
    // @ 查看详情
    check(row) {
      // if (!this.ckId) {
      //   this.$message.warning("请选择一项数据!");
      //   return;
      // }
      sessionStorage.setItem("selectedRow", JSON.stringify([row]));
      let routeUrl = this.$router.resolve({
        path: "/jurisdiction/warehouseAddEdit",
        query: { type: "check" },
        // query: { id: row.id },
        // cs
        // query: { id: '15482607285' },
        
      });
      window.open(routeUrl.href, "_blank");
    },

    // @ 导出
    exportClick() {
      this.exportLoading = true;
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        gridManager: this.$store.state.user.userInfo.account.account, // todo 'wangningjing'
        custId: this.$store.state.user.userInfo.customer.id,
        ...this.arg,
      };
      exportData(data)
        .then((res) => {
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.code == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "管辖的门店列表.xls");
            })
            .finally(() => {
              this.exportLoading = false;
            });
        })
        .catch((err) => {
          this.exportLoading = false;
        });
    },
  },
};
