/*
 * @Author: your name
 * @Date: 2021-10-27 17:29:27
 * @LastEditTime: 2021-10-29 13:50:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\basicInformation\components\sinkManagement\api.js
 */
import http from "@/utils/request";


// 仓库列表
export function shopInfoCkApplyGrid(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/shopInfoCkApplyGrid/page.nd",
    data: data
  });
}

// 导出
export function exportData(data) {
  return http({
      method: "post",
      url: '/fast/user/shopInfoCkApplyGrid/export.nd',
      data:data,
      responseType: 'blob'  
  })
}

// 作废
export function terminalActivityById(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/custShopInfoCk/terminalActivityById.nd",
    data: data
  });
}

